import { z } from "zod";

export const listSchema = z.object({
  // Term used to search items
  q: z.string().optional().nullable().describe("Term used to search items"),

  // Limit the number of items returned.
  limit: z
    .preprocess(
      (v) => Number.parseInt(v as string) ?? 100,
      z.number().min(1).max(100000)
    )
    .optional()
    .describe("Limit the number of items returned."),

  // Page offset.
  page: z
    .preprocess((v) => Number.parseInt(v as string) ?? 1, z.number().min(1))
    .optional()
    .describe("Page offset."),

  // Filter by slug
  slug: z.string().optional().describe("Filter by slug"),

  // The field to order by
  orderBy: z.string().optional().describe("The field to order by"),

  preset: z.string().optional().describe("Filter by preset"),
  createdFrom: z
    .date()
    .optional()
    .nullable()
    .describe("Filter by created from datetime")
    .transform((v) => (typeof v === "string" ? new Date(v) : v)),
  createdTo: z
    .date()
    .optional()
    .nullable()
    .describe("Filter by created to datetime")
    .transform((v) => (typeof v === "string" ? new Date(v) : v)),
});

export type ListInput = z.infer<typeof listSchema>;

export const getSchema = z.object({
  id: z.string(),
});
export type GetInput = z.infer<typeof getSchema>;

export const getByTitleSchema = z.object({
  title: z.string(),
});
export type GetByTitleInput = z.infer<typeof getByTitleSchema>;
export const getBySlugSchema = z.object({
  slug: z.string(),
});

export type GetBySlugInput = z.infer<typeof getBySlugSchema>;
export const getBySkuInput = z.object({
  sku: z.string(),
});

export type GetBySkuInput = z.infer<typeof getBySkuInput>;
export const getByUserIdSchema = z.object({
  userId: z.string(),
});
export type GetByUserIdInput = z.infer<typeof getByUserIdSchema>;

export const getByEmailSchema = z.object({
  email: z.string(),
});
export type GetByEmailInput = z.infer<typeof getByEmailSchema>;

export const deleteSchema = z.object({
  id: z.string(),
});
export type DeleteInput = z.infer<typeof deleteSchema>;

import { z } from "zod";

import { FormType } from "@gsa/db/schema";

import { listSchema } from ".";

export const listFormSubmissionSchema = listSchema.merge(
  z.object({
    userId: z.string().optional().nullable(),

    type: z.array(z.nativeEnum(FormType)).optional().nullable(),
  })
);

export type ListFormSubmissionSchema = z.infer<typeof listFormSubmissionSchema>;

export const createNewsletterFormSubmissionSchema = z.object({
  type: z.literal(FormType.Newsletter),
  data: z.object({
    email: z.string().email(),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateNewsletterFormSubmissionSchema = z.infer<
  typeof createNewsletterFormSubmissionSchema
>;

export const createContactUsFormSubmissionSchema = z.object({
  type: z.literal(FormType.ContactUs),
  data: z.object({
    name: z.string(),
    email: z.string().email(),
    phone: z.string(),
    message: z.string(),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateContactUsFormSubmissionSchema = z.infer<
  typeof createContactUsFormSubmissionSchema
>;

export const createSpeakToSalesRepFormSubmissionSchema = z.object({
  type: z.literal(FormType.SpeakToSalesRep),
  data: z.object({
    name: z.string(),
    email: z.string().email(),
    phone: z.string(),
    message: z.string(),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateSpeakToSalesRepFormSubmissionSchema = z.infer<
  typeof createSpeakToSalesRepFormSubmissionSchema
>;

export const createLookingForSomethingSpecificFormSubmissionSchema = z.object({
  type: z.literal(FormType.LookingForSomethingSpecific),
  data: z.object({
    name: z.string(),
    email: z.string().email(),
    phone: z.string(),
    message: z.string(),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateLookingForSomethingSpecificFormSubmissionSchema = z.infer<
  typeof createLookingForSomethingSpecificFormSubmissionSchema
>;

export const createConsignmentFormSubmissionSchema = z.object({
  type: z.literal(FormType.ConsignmentForm),
  data: z.object({
    name: z.string(),
    email: z.string().email().nullable().optional(),
    phone: z.string().nullable().optional(),
    message: z.string().nullable().optional(),
    items: z.array(
      z.object({
        name: z.string(),
        description: z.string(),
        quantity: z.number(),
      })
    ),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateConsignmentFormSubmissionSchema = z.infer<
  typeof createConsignmentFormSubmissionSchema
>;

export const createSellToUsFormSubmissionSchema = z.object({
  type: z.literal(FormType.SellToUs),
  data: z.object({
    name: z.string(),
    email: z.string().email().nullable().optional(),
    phone: z.string().nullable().optional(),
    message: z.string().nullable().optional(),
    items: z.array(
      z.object({
        name: z.string(),
        description: z.string(),
        quantity: z.number(),
      })
    ),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateSellToUsFormSubmissionSchema = z.infer<
  typeof createSellToUsFormSubmissionSchema
>;

export const createQuestionnaireFormSubmissionSchema = z.object({
  type: z.literal(FormType.InvestmentQuestionnaire),
  data: z.object({
    name: z.string(),
    email: z.string().email(),
    phone: z.string(),
    concernedAboutEconomy: z.boolean(),
    interestedInPreciousMetalInvesting: z.boolean(),
    wouldInvest3kOrMoreInGold: z.boolean(),
    wouldInvest500InSilver: z.boolean(),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateQuestionnaireFormSubmissionSchema = z.infer<
  typeof createQuestionnaireFormSubmissionSchema
>;

export const createCollectionPreorderFormSubmissionSchema = z.object({
  type: z.literal(FormType.CollectionPreorder),
  data: z.object({
    name: z.string(),
    email: z.string().email().nullable().optional(),
    phone: z.string().nullable().optional(),
    message: z.string().nullable().optional(),
    collection: z.object({
      id: z.string(),
      name: z.string(),
    }),
  }),
  recaptchaToken: z.string().optional(),
});

export type CreateCollectionPreorderFormSubmissionSchema = z.infer<
  typeof createCollectionPreorderFormSubmissionSchema
>;

"use client";

import type { ImageLoaderProps } from "next/image";

import { BASE_URL } from "./cdn";

export const loader = ({ src, width, quality }: ImageLoaderProps): string => {
  const url = new URL(src?.replace("/raw", "/image"), BASE_URL);
  url.searchParams.set("w", width.toString());
  url.searchParams.set("q", (quality ?? 75).toString());
  return url.toString();
};
